import React, { useEffect, useState, useRef } from "react";
import Header from '../header/Index';
import "./Index.css";
import { useNavigate, useLocation } from "react-router-dom";
import backArraow from "../../assets/img/back_arrow.svg";
import { getAllEstimates, GetScreenShot } from "../../IndexedDB.js";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import CloseIcon from "@mui/icons-material/Close";
import hamburger from "../../assets/img/hamburgergroup.svg";
import EmailIcon from '@mui/icons-material/Email';
import html2canvas from 'html2canvas';
import { Modal, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { SmtpEmailApi } from '../../actions/estimateAction.jsx';
import toast, { Toaster } from "react-hot-toast";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { jsPDF } from "jspdf";
import { PDFDocument, rgb } from 'pdf-lib';
import { DataLoading, OldSpinnerLoader } from "../../loader/Index.js";
// import greeHseSVG from "../../assets/img/greenhse-logo-small.png"
// import { loadLogo, convertSVGToPNG } from "../../utilities/helpers/commonHelper.js"



const EstimateView = () => {

  // State variables to manage the component's state
  const [headerText, setHeaderText] = useState("Price Calculator");
  const [headerSaveText, setHeaderSaveText] = useState("Done");
  const [estimates, setEstimates] = useState([]);
  const [showHamburgerBlock, setShowHamburgerBlock] = useState(false);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [screenshot, setScreenshot] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [screenShotBase64, setScreenShotBase64] = useState('');
  const [downloadDocument, setDownloadDocument] = useState('')
  const [attachmentName, setAttachmentName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailParams, setEmailParams] = useState({
    submit: 'true',
    name: '',
    email: '',
    subject: '',
    message: '',
    file: '',
    // env: true
  });
  const [openSharePopup, setOpenSharePopup] = useState(false);
  const [shareFormData, setShareFormData] = useState({
    submit: 'true',
    name: '',
    email: '',
    phoneNumber: '',
    attachment: '',
    subject: '',
    message: '',
    admin: 'true',
    env: true
  });

  // Hooks for location, navigation and references
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const myFileRef = useRef(null)

  // Fetch estimates data from an API
  const fetchEstimates = async () => {
    const params = new URLSearchParams(window.location.search);
    const fileName = params.get("file");
    console.log("fileName", fileName)
    const fileNameAfterURLDecode = decodeURIComponent(fileName);
    console.log("fileNameAfterURLDecode", fileNameAfterURLDecode)
    const data = await getAllEstimates(fileNameAfterURLDecode);
    setEstimates(data);
    console.log("estimates", data);
  }

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]); // Extract base64 part only
      };
      reader.onerror = (error) => {
        console.error('Error reading blob:', error);
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  };
  const fetchBlobFromUrl = async (url) => {
    console.log('Fetching Blob from URL:', url); // Log the URL being fetched
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch blob from URL: ${url}`);
      }
      return await response.blob();
    } catch (error) {
      console.error('Error fetching blob from URL:', error);
      throw error;
    }
  }

  console.log("downloadDocument", downloadDocument)
  // Initial fetch of estimates when component mounts and cleanup
  useEffect(() => {
    setAttachmentName('Attachment_' + Date.now() + '.pdf');
    fetchEstimates();
    localStorage.removeItem("isGridEnable");
  }, []);

  const selectedProduct = location.state;

  // Function to handle back button click
  const backclick = () => {
    navigate(-1);
    localStorage.setItem('showHomepage', 'false');
  };

  // Calculate the total amount for the given products
  const calculateTotal = (products) => {
    if (!products || !products.length) return 0;
    return products.reduce((total, product) => total + product.price * product.quantity, 0);
  };

  // Calculate the GST (Goods and Services Tax) for the given total amount
  const calculateGST = (totalAmount) => {
    return (totalAmount * 0.1).toFixed(2); //  GST rate is 10%
  };

  // Get the total amount including GST
  const getTotalWithGST = (totalAmount) => {
    return (totalAmount + parseFloat(calculateGST(totalAmount))).toFixed(2);
  };

  // Toggle the visibility of the hamburger block
  const toggleHamburgerBlock = () => {
    setShowHamburgerBlock(!showHamburgerBlock);
  };

  // Get distinct records based on the SKU of the selected products
  const GetDistinctRecord = (data) => {
    const uniqueTags = [];
    const uniqueResultList = [];
    data.map(light => {
      if (uniqueTags.indexOf(light.selectedProductList.sku) === -1) {
        uniqueTags.push(light.selectedProductList.sku)
        uniqueResultList.push(light);
      }
    });

    return uniqueResultList;
  }

  // Get the quantity of a product based on its SKU
  const GetProductQTY = (data, sku) => {
    var result = data.filter(x => x.selectedProductList.sku == sku);
    // console.log("result", result)
    var qty = 0;

    result.forEach((value, idx) => {
      qty += value.selectedProductList.quantity;
    });
    return qty;
  }

  // Flatten the selected product lists from the estimates
  const allProducts = estimates.lightPlacement ? estimates.lightPlacement.flatMap(light => light.selectedProductList) : [];

  // Update the hidden input with the screenshot's base64 data
  useEffect(() => {
    if (screenShotBase64) {
      const dataUrlInput = document.querySelector('#dataUrlInput');
      if (dataUrlInput) {
        dataUrlInput.value = screenShotBase64;
      }
    }
  }, [screenShotBase64]);

  // Capture a screenshot of the estimate content block
  const estimateScreenshot = () => {
    return new Promise((resolve, reject) => {
      const element = document.querySelector('#estimate-content-block');

      if (!element) {
        console.error('Element with class "estimate-content-block" not found or is invalid.');
        return;
      }

      html2canvas(element,)
        .then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          setScreenShotBase64(imgData)
          resolve(imgData);
        })
        .catch(error => {
          // console.error('Error capturing screenshot:', error);
          reject(error);
        });
    });
  };

  // Handle sending an email with the estimate screenshot attached
  const handleSendEmail = async () => {
    const { name, email, subject, message } = emailParams;
    if (!email) {
      toast.error('Please provide an email address');
      return;
    }

    setIsLoading(true); // Show the loader

    const clientMessage = `Dear ${email},

  <div style="margin-top: 15px; padding: 0;">
      <p style="margin-top: 15px; margin-bottom: 0px;">Thank you for considering Greenhse for your lighting needs.</p>
  
      <p style="margin-top: 0; margin-bottom: 0px; ">If you require a quote, please send a quote request to sales@greenhse.com. Please include your
          lighting layout - this can also be done on the GH Lighting layout app.</p>
  
      <p style="margin-top: 0; margin-bottom: 0px; font-weight:bold">50% DEPOSIT IS REQUIRED TO SECURE ORDER. PAYMENT IS REQUIRED BEFORE DELIVERY/COLLECTION.</p>
        
      <span style="margin-top: 20px;">
      <p style="font-weight:bold;">Best regards,</p>
      <p style="margin: 0;">The Greenhse Team</p>
      <p style="margin: 0;">www.greenhse.com</p>
      <p style="margin: 0;">Sales@greenhse.com</p>
      <p style="margin: 0;">08929729696</p>
      </span>
  </div>`;

    try {
      const screenshot = await estimateScreenshot();
      const formdata = new FormData();
      formdata.append("submit", "true");
      formdata.append("name", email);
      formdata.append("email", email);
      formdata.append("subject", "Greenhse Estimate Document Notification");
      formdata.append("message", clientMessage);
      formdata.append("file", screenShotBase64);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      const response = await SmtpEmailApi(requestOptions);
      // console.log("apiresponse", response)
      if (response.status === true) {
        toast.success('Email sent successfully!');
        setTimeout(() => {
          handleEmailPopupClose();
        }, 2000);
      } else {
        toast.error('Failed to send email');
      }
    } catch (error) {
      // toast.error('Failed to capture screenshot.');
      // console.error('Failed to capture screenshot:', error);
    }
    finally {
      setIsLoading(false); // Hide the loader
    }
  }

  // Handle input changes in the email requesting Quote form
  const handleShareInputChange = (e, field) => {
    if (field === 'attachment') {
      setShareFormData({
        ...shareFormData,
        [field]: e.target.files[0]
      });
    } else {
      setShareFormData({
        ...shareFormData,
        [field]: e.target.value
      });
    }
  };

  // Combines an array of base64-encoded images into a single PDF document.
  const combineBase64DocumentsToPdf = async (base64Documents) => {
    const pdfDoc = await PDFDocument.create();
    // const logoDataUrl = await convertSVGToPNG(greeHseSVG);

    for (const base64 of base64Documents) {
      try {
        // Decode base64 to binary data
        const imageBytes = Uint8Array.from(atob(base64), c => c.charCodeAt(0));

        // Embed the image
        const image = await pdfDoc.embedPng(imageBytes); // Use embedJpg if working with JPGs
        const { width: imgWidth, height: imgHeight } = image.size();

        // Create a page with the same aspect ratio as the image
        const page = pdfDoc.addPage();
        const { width: pageWidth, height: pageHeight } = page.getSize();

        const scaleX = pageWidth / imgWidth;
        const scaleY = pageHeight / imgHeight;
        const scale = Math.min(scaleX, scaleY);

        const scaledWidth = imgWidth * scale;
        const scaledHeight = imgHeight * scale;
        const xOffset = (pageWidth - scaledWidth) / 2;
        const yOffset = (pageHeight - scaledHeight) / 2;

        // Draw the image on the page centered
        page.drawImage(image, {
          x: xOffset,
          y: yOffset,
          width: scaledWidth,
          height: scaledHeight,
        });
      } catch (error) {
        console.error('Error processing image for PDF:', error);
      }
    }

    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], { type: 'application/pdf' });
  };

  const pdfBlobToFile = (blob, fileName) => {
    return new File([blob], fileName, { type: 'application/pdf' });
  };

  const handleSendShareEmail = async () => {
    setIsLoading(true); // Show the loader
    const { name, email, phoneNumber, message, env } = shareFormData;
    const { documentsForDownload } = estimates;

    const base64Documents = [];

    for (const doc of documentsForDownload) {
      if (typeof doc.finalImage === 'string') {
        if (doc.finalImage.startsWith('data:')) {
          const base64 = doc.finalImage.split(',')[1];
          base64Documents.push(base64);
        } else if (doc.finalImage.startsWith('blob:')) {
          try {
            const blob = await fetchBlobFromUrl(doc.finalImage);
            const base64 = await convertBlobToBase64(blob);
            console.log("check blob!!!!", base64);
            base64Documents.push(base64);
          } catch (error) {
            console.error('Error processing Blob URL:', error);
          }
        } else {
          console.warn('Unsupported format or type:', doc.finalImage);
        }
      } else {
        console.warn('Unsupported format or type:', doc.finalImage);
      }
    }

    let pdfBlob;
    try {
      pdfBlob = await combineBase64DocumentsToPdf(base64Documents);
      console.log("pdfBlob", pdfBlob)
    } catch (error) {
      toast.error('Failed to create PDF Blob');
      return;
    }

    const pdfFile = pdfBlobToFile(pdfBlob, 'File.pdf');
    console.log("pdfFile", pdfFile);

    // setGeneratedPdfFile(pdfFile);

    // Create client message
    const clientMessage = `
    <p>Name: ${name}</p>
    <p>Email: ${email}</p>
    <p>Phone Number: ${phoneNumber}</p>
    <p>Message: ${message}</p>
  `;

    try {
      const formData = new FormData();
      formData.append("submit", "true");
      formData.append("admin", 'true');
      formData.append("name", name);
      formData.append("email", email);
      formData.append("subject", "Greenhse Lighting Quote Request");
      formData.append("message", clientMessage);
      formData.append("file", screenShotBase64);
      formData.append("file1", pdfFile);
      formData.append("env", true);

      const requestOptions = {
        method: "POST",
        body: formData,
        redirect: "follow"
      };

      const response = await SmtpEmailApi(requestOptions);

      if (response.status === true) {
        toast.success('Email sent successfully!');
        setTimeout(() => {
          handleSharePopupClose();
        }, 2000);
      } else {
        toast.error('Failed to send email');
      }
    } catch (error) {
      toast.error('Failed to send email');
    }
    finally {
      setIsLoading(false); // Hide the loader
    }
  };

  // Open the email popup and capture a screenshot
  const handleEmailPopupOpen = async () => {
    setOpenEmailPopup(true);
    try {
      const file = await estimateScreenshot();
      setScreenshot(file);
    } catch (error) {
      // console.error('Failed to capture screenshot:', error);
    }
  };

  // Open the email popup and capture a screenshot
  const handleSharePopupOpen = async () => {
    // handleDownload()
    setOpenSharePopup(true);
    try {
      const file = await estimateScreenshot();
      setScreenshot(file);
    } catch (error) {

    }
  };

  // Close the email popup and reset email parameters
  const handleSharePopupClose = () => {
    setShareFormData({
      name: '',
      email: '',
      phoneNumber: '',
      message: '',

    });
    setScreenShotBase64('');
    setOpenSharePopup(false);
  };

  // Close the email popup and reset email parameters
  const handleEmailPopupClose = () => {
    setEmailParams({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
    setScreenShotBase64('');
    setOpenEmailPopup(false);
  };

  // Fetch and set the selected file from localStorage
  useEffect(() => {
    getFileAndSet();
  }, []);


  // Retrieve the file from localStorage
  const getFileAndSet = () => {
    const estimates = localStorage.getItem("selectedFile");
    if (estimates) {
      const file = JSON.parse(estimates);
      setSelectedFile(file);
    }
  };

  // Handle input changes in the email form
  const handleInputChange = (e, field) => {
    setEmailParams({
      ...emailParams,
      [field]: e.target.value
    });
  };

  return (
    <div className="estimate-page-block">
      <div className="back-button">
        <button onClick={backclick}>
          <img src={backArraow} alt="Back" />
          Back
        </button>
      </div>
      <div className="estimate-block">
        <button onClick={backclick}>
          {headerSaveText}
        </button>
      </div>
      <Header headerText={headerText} headerSaveText={headerSaveText} showEstimateName={false} showEditIcon={false} />
      <div id='estimate-content-block' >
        <div className='estimate-button-row'>
          <div className='estimate-button-title'>
            <span className='product-name'>PRODUCT</span>
          </div>
          <div className='product-details-summary'>
            <span className='product-type-info'>RATE(AUD)</span>
            <span className='product-type-info'>QUANTITY</span>
            <span className='product-type-info'>AMOUNT(AUD)</span>
          </div>
        </div>
        {estimates.lightPlacement && GetDistinctRecord(estimates.lightPlacement).map((light, idx) => (
          <>
            <div className='estimate-button-row active-estimate'>
              <div className='estimate-button-title estimate-active-title'>
                <span className='product-name active-product'><LightbulbIcon className="light-icon" style={{ color: light.selectedProductList.color }} /><span className="active-name">{light.selectedProductList.sku}</span></span>
              </div>
              <div className='product-details-summary active-summary'>
                <span className='product-type-info'>{light.selectedProductList.price}</span>
                <span className='product-type-info'>{GetProductQTY(estimates.lightPlacement, light.selectedProductList.sku)}</span>
                <span className='product-type-info'>{light.selectedProductList.price * GetProductQTY(estimates.lightPlacement, light.selectedProductList.sku)}</span>
              </div>
            </div>
          </>
        ))}
        <div className="total-info-area">
          <div className='total-info-block'>
            <div className="terms-text-info">
              {/* <p>Terms & Conditions
                N.B All transactions are subject to Greenhouse Terms and Condtions and Privacy Policy as detailed on the greenhouse website www.greenhse.com
              </p> */}
              <p> Terms & Conditions: All transactions are subject to Greenhouse Terms and Conditions and Privacy Policy as detailed on the Greenhouse website www.greenhse.com
              </p>
            </div>
            <div className="total-info-inner-block">
              <div className="total-info-inner">
                <span className='product-type-info'>SUB TOTAL</span>
                <span className='product-type-info empty-type-info'></span>
                <span className='product-type-info'>{calculateTotal(allProducts)}</span>
              </div>
              <div className="total-info-inner">
                <span className='product-type-info'>10% GST</span>
                <span className='product-type-info empty-type-info'></span>
                <span className='product-type-info'>{calculateGST(calculateTotal(allProducts))}</span>
              </div>
              <div className="total-info-inner">
                <span className='product-type-info'>TOTAL</span>
                <span className='product-type-info empty-type-info'></span>
                <span className='product-type-info'>{getTotalWithGST(calculateTotal(allProducts))}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showHamburgerBlock === false && (
        <div className="hamburger-bar-icon" onClick={toggleHamburgerBlock}>
          <img src={hamburger} alt="Hamburger Icon" />
        </div>
      )}
      {showHamburgerBlock && (
        <div className="hamburger-block crop-icon-block">
          <div className="hamburger-inner crop-section-block">
            <>
              <div className="popup-custom-icon-block">
                <div className="delete-icon-block contact-details-icon custom-block2">
                  <ContactMailIcon onClick={handleSharePopupOpen} />
                </div>
                <div className="edit-icon-block custom-block3 mail-icon-block">
                  <EmailIcon onClick={handleEmailPopupOpen} alt="Edit Icon" />
                </div>
              </div>
              <div className="hamburger-bar-icon close-icon-block">
                <CloseIcon onClick={toggleHamburgerBlock} />
              </div>
            </>
          </div>
        </div>
      )}

      <Dialog className="email-estimate-popup" open={openEmailPopup}
        // onClose={handleEmailPopupClose}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            handleEmailPopupClose();
          }
        }}
        aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title" className="estimate-title">Email Estimate</DialogTitle>
        <DialogContent>

          <TextField
            margin="dense"
            id="email"
            label="Email Address"
            placeholder="Email"
            type="email"
            fullWidth
            value={emailParams.email}
            onChange={(e) => handleInputChange(e, 'email')}
            className="email-estimate-input"
          />

          <p className="attechment-text">Attachment:</p>
          {screenShotBase64 &&
            <img id="screenshotImage" src={screenShotBase64} alt="Screenshot" style={{ maxWidth: '100%' }} />
          }

        </DialogContent>
        <DialogActions>
          <Button className="cancelEstimate" onClick={handleEmailPopupClose} color="primary">
            Cancel
          </Button>
          {isLoading && <OldSpinnerLoader />}
          <Button className="saveEstimate" onClick={handleSendEmail} color="primary">
            Send
          </Button>
        </DialogActions>
        <Toaster position="top-right" reverseOrder={false} />
      </Dialog>

      <Dialog className="share-popup" open={openSharePopup}
        // onClose={handleSharePopupClose}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            handleSharePopupClose();
          }
        }}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className="share-title">Request a Quote</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="share-name"
            label="Name"
            placeholder="Name"
            type="text"
            fullWidth
            value={shareFormData.name}
            onChange={(e) => handleShareInputChange(e, 'name')}
            className="share-input"
          />
          <TextField
            margin="dense"
            id="share-email"
            label="Email Address"
            placeholder="Email"
            type="email"
            fullWidth
            value={shareFormData.email}
            onChange={(e) => handleShareInputChange(e, 'email')}
            className="share-input"
          />
          <TextField
            margin="dense"
            id="share-phone"
            label="Phone Number"
            placeholder="Phone Number"
            type="text"
            fullWidth
            value={shareFormData.phoneNumber}
            onChange={(e) => handleShareInputChange(e, 'phoneNumber')}
            className="share-input"
          />
          <TextField
            margin="dense"
            id="message"
            label="Message"
            placeholder="Write your message here..."
            multiline
            minRows={4}
            fullWidth
            value={shareFormData.message}
            onChange={(e) => handleShareInputChange(e, 'message')}
            className="message-text-area"
          />

          <p className="attechment-text">Attachment:</p>
          <TextField
            margin="dense"
            id="share-file-name"
            placeholder="Attachment"
            type="text"
            fullWidth
            value={attachmentName}
            readOnly
            className="share-input"
          />
          <p className="attechment-text">Attachment:</p>
          {screenShotBase64 &&
            <img id="screenshotImage" src={screenShotBase64} alt="Screenshot" style={{ maxWidth: '100%' }} />
          }

        </DialogContent>
        <DialogActions>
          {isLoading && <OldSpinnerLoader />}
          <Button className="cancelShare" onClick={handleSharePopupClose} color="primary">
            Cancel
          </Button>
          <Button className="saveShare" onClick={handleSendShareEmail} color="primary">
            Send
          </Button>
        </DialogActions>
        <Toaster position="top-right" reverseOrder={false} />
      </Dialog>

    </div>
  );
};

export default EstimateView;
