import React, { useEffect, useRef, useState } from "react";
import { getEstimateName } from "../../IndexedDB";
import "./Index.css";
import { updateEstimateName } from '../../IndexedDB'
import Popup from "reactjs-popup";
import alertIcon from "../../assets/img/alert_icon.svg";
import EditIcon from '@mui/icons-material/Edit';

const Header = (props) => {
  
 // State variables to manage the component's state
  const { headerText, showEstimateName, showEditIcon } = props;
  const [estimateName, setEstimateName] = useState("");
  const [newEstimateName, setNewEstimateName] = useState("");
  const clickRef = useRef();

  // Function to fetch the estimate name from IndexedDB
  const fetchEstimateName = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const fileName = params.get("file");
      const fetchedFile = await getEstimateName(fileName);

      if (fetchedFile) {
        setEstimateName(fetchedFile);
        setNewEstimateName(fetchedFile);
        // return fetchedFile;
      } else {
        console.error("File not found in the database:", fileName);
      }
    } catch (error) {
      console.error("Error fetching file:", error);
    }
  };

  // Function to update the estimate name in IndexedDB
  const UpdateEstimateName = async () => {
    const params = new URLSearchParams(window.location.search);
    var fileName = params.get("file");
    fileName = decodeURIComponent(fileName);
    await updateEstimateName(fileName, newEstimateName);
    setEstimateName(newEstimateName);
  }

  // Fetch the estimate name when the component mounts
  useEffect(() => {
    fetchEstimateName();
  }, []);

  return (
    <div className="header">
      <h5>{headerText}</h5>
      {/* <div  className="save-button">
       {props.headerSaveText}
      </div> */}
      <div className="edit-header">
        {showEditIcon && (
          <Popup
            trigger={<EditIcon />}
            position="right center"
            className="delete-popup-content-content"
          >
            {(close) => (
              <div className="delete-popup-button-block rename-popup">
                <div className="input-custom-block">
                  <h5>Rename</h5>
                  <input
                    type="text"
                    value={newEstimateName}
                    onChange={(e) => setNewEstimateName(e.target.value)}
                  />
                </div>
                <div className="delete-btn-block edit-delete-btn-block">
                  <div className="delete-btn-block-inner">
                    <button onClick={close}>Cancel</button>
                    <button className="delete-block" onClick={() => { clickRef.current.open(); close(); }}>Rename</button>
                  </div>
                </div>
              </div>
            )}
          </Popup>
        )}
        <Popup
          ref={clickRef}
          position="right center"
          className="delete-popup"
        >
          {(close) => (
            <div className="delete-popup-button-block">
              <div className="alert-icon-info">
                <div className="alert-icon">
                  <img src={alertIcon} alt="Alert" />
                </div>
                <h5>Confirm!</h5>
              </div>
              <p>Are you sure you want to Rename this estimate?</p>

              <div className="delete-btn-block">
                <button onClick={close}>Cancel</button>
                <button
                  className="delete-block button"
                  onClick={() => {
                    UpdateEstimateName();
                    close();
                  }}
                >
                  Rename
                </button>
              </div>
            </div>
          )}
        </Popup>
        <div className="header-sub-info-text">
          {showEstimateName && <h6>{estimateName}</h6>}
        </div>
      </div>
    </div>
  );
};

export default Header;
