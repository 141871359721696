import { combineReducers } from 'redux';
import productReducer from './productReducer';
// Import other reducers if you have them

const rootReducer = combineReducers({
  products: productReducer,
  // Add other reducers here if needed
});

export default rootReducer;


