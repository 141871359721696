import "./Index.css";
import React, { useState, useEffect } from "react";
import { GetAllCategoriesApi, GetProductApi, GetAllProductApi } from "../../actions/lightPlacementActions.jsx";
import { DataLoading, OldSpinnerLoader } from "../../loader/Index.js";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../header/Index.js";
import backArraow from "../../assets/img/back_arrow.svg";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { GetAllLightPlacement } from "../../IndexedDB.js";
// import EditView from "../editView/Index.js";
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS, CallBackProps } from 'react-joyride';


const LightPlacement = (props) => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [childrenCategories, setChildrenCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [productData, setProductData] = useState([]);
  const [allProductData, setAllProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showRoomType, setShowRoomType] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [lightPlacementPositions, setLightPlacementPositions] = useState([]);
  // const [selectedPrice, setSelectedPrice] = useState(null);
  // const [isLoadingRoomType, setIsLoadingRoomType] = useState(false); 
  const [inputValue, setInputValue] = useState('');
  const [run, setRun] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   GetAllCategories();
  //   //Getdatabase Data
  //   getAllPositions();
  // }, []);

  useEffect(() => {
    // Check if the user has visited before
    const lightPlacementViewVisited = localStorage.getItem('lightPlacementViewVisited');
    if (lightPlacementViewVisited) {
      setRun(false);
    }
    // alert(props.categoryId)
    if (props.categoryId == null) {
      // if (selectedCategory) {
      //   GetProductData(selectedCategory.id);
      //   setShowProducts(true);
      // }
    }
    else {
      GetProductData(props.categoryId.id);
      setShowProducts(true);
      setSelectedCategory(props.categoryId);
      setShowRoomType(false);
    }
    //GetDatabase data
    GetAllCategories();
    getAllPositions();
  }, []);

  const GetAllCategories = async () => {

    setIsLoading(true);
    try {
      const categoriesResponse = await GetAllCategoriesApi(59);
      setCategoriesData(categoriesResponse);

      if (categoriesResponse.children) {
        setIsLoading(true);
        const childrenIds = categoriesResponse.children.split(',');
        const childrenDetails = await Promise.all(childrenIds.map(id => GetAllCategoriesApi(id)));
        setIsLoading(false);
        setChildrenCategories(childrenDetails);

        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  //   const handleClick = () => {
  //   props.onSendMessage(inputValue);
  // };

  const GetProductData = async (categoryId) => {
    try {
      setIsLoading(true);
      const productResponse = await GetProductApi(categoryId);
      setProductData(productResponse.items);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching product data for category with ID", categoryId, ":", error);
    }
  };

  const GetAllProductData = async () => {
    try {

      if (!showAllProducts) {
        setIsLoading(true);
        const allproductResponse = await GetAllProductApi();
        setIsLoading(false);
        setAllProductData(allproductResponse.items);
      }

      if (showAllProducts) {
        // setShowRoomType(true);
        // setShowProducts(true);
        setShowAllProducts(false);
      }

      if (!showAllProducts) {
        setShowAllProducts(true);
      }
    } catch (error) {
      console.error("Error fetching all product data:", error);
    }
  };


  const handleCategoryClick = (category) => {
    //setIsLoading(true); // Start loader when fetching children categories
    try {
      // console.log("Called!");
      setSelectedCategory(category);
      setShowRoomType(false); // Hide the room type selection
      // Fetch product data for the selected category
      setShowProducts(true)
      // setIsLoading(true);
      GetProductData(category.id);
      // setIsLoading(false);
    } catch (error) {
      console.error("Error fetching children categories:", error);
    }
  };

  const handleBackButtonClick = () => {
    setSelectedCategory(null);
    setShowRoomType(false);
    setShowProducts(false);
    setShowAllProducts(false);
    navigate(-1);
  };

  const getAllPositions = async () => {
    const params = new URLSearchParams(window.location.search);
    const fileName = params.get("file");
    var records = await GetAllLightPlacement(fileName)

    if (records.length > 0) {
      // console.log("inside", records);
      setLightPlacementPositions(records)
    }
  }

  const handleProductClick = (product, productColor) => {
    let productSku = product.sku;
    // if product color is undefined
    productColor = productColor || '#212529';
    // localStorage.setItem('hashColor', productColor);select-collapse-collapse
    const params = new URLSearchParams(window.location.search);
    const fileName = params.get("file");
    // const circleComponents = params.get("circles");
    var circleComponents = new URLSearchParams(window.location.href);
    // console.log("circle", circleComponent);
    circleComponents = circleComponents.get('circles');
    // console.log("fileName", fileName, "productColor", productColor, "circleComponents", circleComponents)

    var quantity;
    var circleComponent = circleComponents ? decodeURIComponent(circleComponents) : "";
    var hashColor = '';
    var lightSize = 'small'
    switch (selectedCategory.name) {

      case 'Small Size Room (2x2m size maximum)':
        circleComponent ? circleComponent += `,OneCircles_${productColor}` : circleComponent = `OneCircles_${productColor}`;
        quantity = 1;
        hashColor = `OneCircles_${productColor}`
        break;
      case 'Medium Size Room (From 2x2m to 2.5x3.5m)':
        circleComponent ? circleComponent += `,TwoCircles_${productColor}` : circleComponent = `TwoCircles_${productColor}`;
        if (productSku.startsWith('P18S')) {
          quantity = 1;
          hashColor = `OneCircles_${productColor}`
          lightSize = 'medium'
        } else {
          quantity = 2;
          hashColor = `TwoCircles_${productColor}`
        }
        break;
      case 'LARGE SIZE ROOMS (From 2x2m to 4x4m)':
        circleComponent ? circleComponent += `,FourCircles_${productColor}` : circleComponent = `FourCircles_${productColor}`;
        if (productSku.startsWith('P24')) {
          quantity = 1;
          hashColor = `OneCircles_${productColor}`
          lightSize = 'large'
        } else {
          quantity = 4;
          hashColor = `FourCircles_${productColor}`
        }
        break;
      case 'X-LARGE SIZE ROOMS (From 2x4m to 4x6m)':
        circleComponent ? circleComponent += `,SixCircles_${productColor}` : circleComponent = `SixCircles_${productColor}`;

        if (productSku.startsWith('P24')) {
          quantity = 2;
          hashColor = `TwoCircles_${productColor}`
          lightSize = 'large'
        } else if (productSku.startsWith('P18S')) {
          quantity = 2;
          hashColor = `TwoCircles_${productColor}`
          lightSize = 'medium'
        } else {
          quantity = 6;
          hashColor = `SixCircles_${productColor}`
        }
        break;
      case 'X-LARGE SIZE ROOMS (From 2x5m to 4x8m)':
        circleComponent ? circleComponent += `,EightCircles_${productColor}` : circleComponent = `EightCircles_${productColor}`;
        if (productSku.startsWith('P24')) {
          quantity = 2;
          hashColor = `TwoCircles_${productColor}`
          lightSize = 'large'
        } else {
          quantity = 8;
          hashColor = `EightCircles_${productColor}`
        }
        break;
      case 'X-LARGE SIZE ROOMS (From 2.5 x5m to 3x6m)':
        circleComponent ? circleComponent += `,EightCircles_${productColor}` : circleComponent = `EightCircles_${productColor}`;
        if (productSku.startsWith('P24')) {
          quantity = 2;
          hashColor = `TwoCircles_${productColor}`
          lightSize = 'large'
        } else if (productSku.startsWith('P18S')) {
          quantity = 2;
          hashColor = `TwoCircles_${productColor}`
          lightSize = 'medium'
        } else {
          quantity = 8;
          hashColor = `EightCircles_${productColor}`
        }
        break;
      default:
        circleComponent = null;
        break;
    }

    var selectedProduct = {
      id: product.id,
      name: product.name,
      sku: product.sku,
      price: product.price,
      quantity: quantity,
      color: productColor
    }

    //  Get localstorage array 
    let curentPositionArr = JSON.parse(localStorage.getItem('currentNodePositions'));
    let editCurrentNodeId = JSON.parse(localStorage.getItem('editCurrentNodeId'));

    if (Array.isArray(curentPositionArr) == true) {
      if (curentPositionArr.length >= 1) {
        if (props.categoryId == null && editCurrentNodeId == null) {
          let lastIndex = curentPositionArr.length - 1;
          let lastArrObj = curentPositionArr[lastIndex];
          lastArrObj.placeLight = hashColor;
          lastArrObj.categoryId = selectedCategory
          lastArrObj.selectedProductList = selectedProduct
          lastArrObj.fileName = fileName
          lastArrObj.lightSize = lightSize
          curentPositionArr[lastIndex] = lastArrObj;
          // let lastArrElement = curentPositionArr[curentPositionArr.length - 1]
          // set active page 
          let activePageIndex = parseInt(localStorage.getItem("activePageIndex"))
          curentPositionArr[lastIndex].pageIndex = activePageIndex
        }
        else {
          console.log("updated else array before", curentPositionArr);
          for (let i = 0; i < curentPositionArr.length; i++) {
            if (curentPositionArr[i].nodeId == props.nodeId || curentPositionArr[i].nodeId == editCurrentNodeId) {
              curentPositionArr[i].placeLight = hashColor;
              curentPositionArr[i].selectedProductList = selectedProduct
              curentPositionArr[i].categoryId = selectedCategory
              curentPositionArr[i].lightSize = lightSize

              if (editCurrentNodeId) {
                console.log('updated array if edit the node');
                localStorage.removeItem("editCurrentNodeId");
              }
            }
          }
        }
      }

      // Update the property
      localStorage.setItem('currentNodePositions', JSON.stringify(curentPositionArr));
    }
    else {
      if (props.categoryId != null) {
        for (let i = 0; i < lightPlacementPositions.length; i++) {
          if (lightPlacementPositions[i].nodeId == props.nodeId) {
            lightPlacementPositions[i].placeLight = hashColor;
          }
        }
      }
    }
    //navigate(`/editview?file=${decodeURIComponent(fileName)}`, { state: selectedProduct });
    props.onSendMessage(selectedProduct);
    props.setShowLightPlacementComp(false)
    // &placeLight=${circleComponent}
  };

  const steps = [
    {
      target: '.select-room-type',
      content: 'By clicking on this button you can select room type',
      disableBeacon: true,
      hideCloseButton: true,
      placement: "auto"
    },
    {
      target: '.select-product-type',
      content: 'By clicking on this button you can select prooduct type',
      hideCloseButton: true,
      hideBackButton: true,
      placement: "auto"
    }
  ];

  // joyride callback function
  const handleJoyrideCallback = (data) => {
    const { action, index, origin, status, type } = data;
    console.log('data', data);
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // You need to set our running state to false, so we can restart if we click start again.
      localStorage.setItem('lightPlacementViewVisited', 'true');
      setRun(false);
    }
  };

  return (
    <>
      <Joyride
        callback={(data) => handleJoyrideCallback(data)}
        steps={steps}
        run={run}
        styles={{
          options: {
            primaryColor: '#5CB226',
          },
        }}
        continuous
        showSkipButton
        showProgress
        disableOverlayClose={true}
        disableScrollParentFix={true}
      />
      <div className="row">
        <div className="col-md-12 select-lighting-block-area">
          <div className="select-lighting-block">
            <div className="lightheader select-light-header">
              {/* <div className="custom-back-btn-align header-inner-text">
                <button onClick={handleBackButtonClick}><ChevronLeftIcon />Back</button>
              </div> */}
              <div className="header-inner-text">Selected lighting</div>
              <div className="header-inner-text"></div>
            </div>

            {!showAllProducts && (
              <div className="select-collapse-area-block">
                <div className="select-collapse-collapse">
                  <div className="select-collapse-button">
                    {/* {selectedCategory ?  
                  // (
                  //   <button type="button" className="btn" >{selectedCategory.name}  < ExpandMoreIcon className="expand-icon" /> </button>
                  // ) 
                  // : (*/}
                    <button type="button" className={`select-room-type btn ${showRoomType ? '' : 'collapsed'}`} onClick={() => {
                      // if (props.categoryId == null) {
                      setShowRoomType(!showRoomType);
                      // }
                      // else {
                      //   setShowRoomType(false);
                      // }
                    }} >
                      {selectedCategory ? selectedCategory.name : "Select Room Type "}   <ExpandMoreIcon className="expand-icon" />
                    </button>

                    {/*   // )
              } */}
                  </div>
                  <div id="select-collapse" className={`collapse${showRoomType ? ' show' : ''}`} >
                    {isLoading ? (
                      // <div> loading....</div>
                      // <OldSpinnerLoader />
                      <></>
                    ) :
                      (
                        ((childrenCategories.length > 0)) &&
                        <>
                          {childrenCategories.map((category, index) => (
                            <div key={index} onClick={() => handleCategoryClick(category)} >
                              <div className="categoryblock">
                                <div><strong>{category.name}</strong></div>
                                <div>{category.custom_attributes.find(attr => attr.attribute_code === 'description')?.value}</div>
                              </div>
                            </div>
                          ))}
                        </>
                      )
                    }
                    {/* {selectedCategory && (
                    <div className="collapse-nav-block">{selectedCategory.name}</div>
                  )} */}
                  </div>
                </div>
                {/* {isLoading && <OldSpinnerLoader />} */}
                <div className="select-collapse-collapse">
                  <div className="select-collapse-button">
                    <button type="button" className="btn select-product-type" data-bs-toggle="collapse" data-bs-target="#select-collapse2">
                      Select Product Type  < ExpandMoreIcon className="expand-icon" />
                    </button>
                  </div>
                  <div id="select-collapse2" className={`collapse${(showProducts == true) ? ' show' : ''}`}>
                    {selectedCategory && (
                      <div className="selected-lighting">
                        {Array.isArray(productData) && productData.map((product, index) => (
                          <div className="selected-light-block" key={index}>
                            <div className="selected-light-name" key={index} onClick={() => handleProductClick(product, product.custom_attributes.find(attr => attr.attribute_code === 'hashcolor')?.value)}>
                              <div className="product-image-block">
                                <img src={`https://greenhse.com/pub/media/catalog/product${product.media_gallery_entries[0].file}`} alt={product.name} />
                              </div>
                              <div className="product-name-info">{product.sku}</div>
                            </div>
                            <div className="selected-light-content-info">
                              <div className="custom-bottom-margin selected-light-poduct-name">{product.name}</div>
                              {/* <div className="custom-bottom-margin">{product.custom_attributes.find(attr => attr.attribute_code === 'meta_description')?.value}</div> */}
                              <div className="custom-bottom-margin light-price-text-info">${product.price}+GST</div>
                              <a href={`https://greenhse.com/${product.custom_attributes.find(attr => attr.attribute_code === 'url_key')?.value}.html`}  target="_blank">
                                Click here for detailed information
                              </a>
                              <div style={{ color: product.custom_attributes.find(attr => attr.attribute_code === 'hashcolor')?.value }}><LightbulbIcon className="light-icon" /></div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div>
              {/* {<button onClick={GetAllProductData} className="allproduct-button"><span className=""><LightbulbIcon /></span> All Lights</button>} */}
              {showAllProducts && (
                <div className="selected-lighting">
                  {Array.isArray(allProductData) && allProductData.map((product, index) => (
                    <div className="selected-light-block" key={index}>
                      <div className="selected-light-name">
                        <div className="product-image-block">
                          {product.media_gallery_entries.length > 0 && (
                            <img src={`https://greenhse.com/pub/media/catalog/product${product.media_gallery_entries[0].file}`} alt={product.name} />
                          )}
                        </div>
                        <div className="product-name-info">{product.sku}</div>
                      </div>
                      <div className="selected-light-content-info">
                        <div className="custom-bottom-margin selected-light-poduct-name">{product.name}</div>
                        {/* <div className="custom-bottom-margin">{product.custom_attributes.find(attr => attr.attribute_code === 'meta_description')?.value}</div> */}
                        <div className="custom-bottom-margin light-price-text-info">${product.price}+GST</div>
                        <a href={`https://greenhse.com/${product.custom_attributes.find(attr => attr.attribute_code === 'url_key')?.value}`}>
                          Click here for detailed information
                        </a>
                        <div style={{ color: product.custom_attributes.find(attr => attr.attribute_code === 'hashcolor')?.value }}><LightbulbIcon className="light-icon" /></div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

        </div>



      </div>
      {isLoading && <OldSpinnerLoader />}
    </>
  );
};

export default LightPlacement;
