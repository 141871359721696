import React from 'react';
import greenhse from '../../assets/img/greenhse.gif';
import "./Index.css";

const Homepage = () => {
  return (
    <div className='homepage-content-block'>
      <img src={greenhse} alt="right-icon" />
    </div>
  );
};

export default Homepage;
