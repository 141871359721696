import React from 'react';
import { Routes, Route } from "react-router-dom";
import Homepage from "../components/homepage/Index.jsx"
import ScanerView from '../components/scanerView/Index.jsx';
import EditView from '../components/editView/Index.js'
import LightPlacement from '../components/lightPlacementView/Index.jsx';
import EstimateView from '../components/estimate/Index.js';

const Routing = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<Homepage />} /> */}
      <Route path="/" element={<ScanerView />} />
      <Route path="/editview" element={<EditView />} />
      <Route path="/lightplacementview" element={<LightPlacement />} />
      <Route path="/estimate" element={<EstimateView />} />
    </Routes>
  )
}

export default Routing;
